import React from 'react'
import { graphql } from 'gatsby'
import BlockHeroHome from 'components/blocks/BlockHeroHome'
import BlockFourUp from 'components/blocks/BlockFourUp'
import BlockCompare from 'components/blocks/BlockCompare'
import BlockSpecs from 'components/blocks/BlockSpecs'
import BlockSlideshowCompare from 'components/blocks/BlockSlideshowCompare'
import BlockLinks from 'components/blocks/BlockLinks'
import BlockTestimonials from 'components/blocks/BlockTestimonials'
import BlockContact from 'components/blocks/BlockContact'
import Footer from 'components/Footer'
import Seo from 'components/Seo'

// markup
const Page = ({ data }) => {
  const customerShorts = data.allPrismicCustomerShort.nodes
  const content = {
    blockSpecs: {
      services: [
        {
          title: '空间',
          details: ['储藏空间', '休息区', '清洁间', '监控室', '配电间'],
        },
        {
          title: '服务',
          details: [
            '场地清洁卫生',
            '消防安全培训',
            '7/24管家服务',
            '物业关系维护',
          ],
        },
        {
          title: '设备',
          details: [
            '智能水电表',
            '智能门锁',
            'CCTV',
            '三星水槽',
            '油水分离器',
            '消防喷淋',
            '干粉灭火器及灭火毯',
            '排烟罩',
          ],
        },
        {
          title: '公共设施',
          details: ['水、电、煤气', 'WIFI', '空调', '垃圾临时存放间'],
        },
      ],
      buttonAddClass: 'reserve_equip',
    },
    testimonials: {
      customers: customerShorts.map((customer) => {
        return {
          restaurant: customer.data.title.text,
          name: customer.data.subtitle.text,
          gatsbyImage: customer.data.image,
          video: customer.data.video.text,
          buttonPrefix: 'hp_video',
        }
      }),
    },
  }
  const seoContent = {
    titleFull: `星选闪电-美食店铺出租-外卖选址-小吃档口-如何开外卖店-开餐饮店-共享厨房`,
    description: `星选闪电为您提供餐饮证照支持与设备进场，无需担心繁琐办证流程,优质商圈的人流量，匹配周边潜在顾客，让您再也无需担心缺少餐饮需求。`,
    keywords: `外卖选址,外卖档口,共享厨房,餐饮档口,美食店铺出租,开餐饮店,如何开外卖店,小吃档`,
  }
  return (
    <div>
      <Seo content={seoContent} />
      <BlockHeroHome />
      <BlockFourUp />
      <BlockCompare />
      <BlockSpecs content={content.blockSpecs} />
      <BlockSlideshowCompare />
      <BlockLinks />
      <BlockTestimonials content={content.testimonials} />
      <BlockContact buttonAddClass='reserve_bottom' />
      <Footer />
      <link rel='canonical' href='https://www.xingxuanshandian.com' />
    </div>
  )
}

export default Page

export const query = graphql`
  query {
    allPrismicCustomerShort(
      sort: { order: DESC, fields: last_publication_date }
    ) {
      nodes {
        data {
          title {
            text
          }
          subtitle {
            text
          }
          image {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            thumbnails {
              larger {
                gatsbyImageData
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          video {
            text
          }
        }
      }
    }
  }
`
