import React, { useState, useEffect } from 'react'
import Image from 'components/Image'
import ImagePng from 'components/ImagePng'
import ButtonContact from 'components/ButtonContact'
import Inview from 'components/Inview'
import ReactPlayer from 'react-player'

const Component = () => {
  const [isMounted, setIsMounted] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const videoStart = () => {
    setIsPlaying(true)
  }

  const isLoadingClass = isPlaying ? 'playing' : 'loading'
  return (
    <section className='block-hero-home relative'>
      <Inview triggerOnce={true}>
        <Top />
        <div className='video--holder desktop hidden lg:block z-0 relative md:absolute right-0 top-0 fade-in'>
          <div className='image-bg'>
            {/* <Image filename='image-hero-tw.jpg' /> */}
            <div className={`react-player-wrap ${isLoadingClass}`}>
              {isMounted && (
                <ReactPlayer
                  className='react-player'
                  onStart={videoStart}
                  type='video/mp4'
                  playing
                  muted
                  loop
                  playsinline
                  width='100%'
                  height='100%'
                  url='/videos/banner-home_720p-optimized.mp4'
                />
              )}
            </div>
          </div>
        </div>
      </Inview>
      <Inview>
        <div className='lg:hidden relative z-20 video-mobile-wrap'>
          <div className='video--holder mobile fade-in relative'>
            <div className='image-bg'>
              <Image filename='image-hero-tw.jpg' />
              {/* <video
                type='video/mp4'
                autoPlay
                muted
                loop
                playsInline
                src='/videos/banner-home_720p-optimized.mp4'
              /> */}
            </div>
          </div>
        </div>
      </Inview>
      <Inview>
        <Bottom />
      </Inview>
    </section>
  )
}

export default Component

const Top = () => {
  return (
    <div className={`container top z-10 relative flex items-end`}>
      <div className='grid sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        {/* left text */}
        <div className='bg--grey-2 col-span-6 md:col-span-12 lg:col-span-7 pt-16 pb-20 md:pt-16 md:pr-16 lg:pt-14 lg:pb-14 lg:pr-14 relative bg--left'>
          <Inview>
            <div className='mb-8 lg:mb-12 w-450 fade-in-up stagger-400'>
              <p>重新定义，外卖新餐饮</p>
            </div>
            <h1 className='h1 color--primary mb-10 md:mb-12 fade-in-up stagger-600'>
              仅需三天 <br />
              万元开店 <br />
              就来星选闪电
            </h1>
            <div className='mb-8 lg:mb-12 w-450 fade-in-up stagger-800'>
              <p>我们助力餐饮商户，更智能选址，更便捷开店，更优成本运营。</p>
              <p>您只需负责用心出品美味佳肴，其他的交给我们。</p>
            </div>
            <div className='lg:flex items-center fade-in-up stagger-1000'>
              <ButtonContact addClass='reserve_hp' />
              <div className='logos flex-grow mt-14 lg:mt-0 lg:pl-8'>
                <ImagePng filename='logos-top.png' />
              </div>
            </div>
          </Inview>
        </div>
      </div>
      <div className='scroll--indicator'>向下划动</div>
    </div>
  )
}
const Bottom = () => {
  return (
    <div className='container bottom z-10 relative'>
      <Inview>
        <div className='grid grid-cols-4 lg:grid-cols-12 mt-8 lg:mt-40 mb-10 lg:mb-14 color--white relative'>
          <div className='spacer hidden lg:block bg--primary col-span-1 col-start-4 pt-12 pb-10' />
          <div className='bg--primary col-span-2 pt-4 pb-4 lg:pt-12 lg:pb-10'>
            <div className='fade-in-up'>
              <h3 className='h3 small color--accent'>7+</h3>
              <p>城市覆盖</p>
            </div>
          </div>
          <div className='bg--primary col-span-2 pt-4 pb-4 lg:pt-12 lg:pb-10'>
            <div className='fade-in-up stagger-200'>
              <h3 className='h3 small color--accent'>300+</h3>
              <p>门店数量</p>
            </div>
          </div>
          <div className='bg--primary col-span-2 pt-4 pb-8 lg:pt-12 lg:pb-10'>
            <div className='fade-in-up stagger-400'>
              <h3 className='h3 small color--accent'>500+</h3>
              <p>合作品牌</p>
            </div>
          </div>
          <div className='bg--primary col-span-2 pt-4 pb-8 lg:pt-12 lg:pb-10'>
            <div className='fade-in-up stagger-600'>
              <h3 className='h3 small color--accent'>4000+</h3>
              <p>商户数量</p>
            </div>
          </div>
        </div>
      </Inview>
    </div>
  )
}
