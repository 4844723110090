import React from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'
import LinkWrap from 'components/LinkWrap'

const Component = () => {
  return (
    <section
      id='block-links'
      className='BlockLinks BlockLinks__Section-nfxl91-0 kczVee relative'
    >
      <div className='container absolute z-10 right-0 left-0 bottom-0 bg--left'>
        <Inview>
          <div className=''>
            <div className='grid-wrap grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
              <div className='col-span-4 lg:col-span-5 bg--grey-2 pt-20 md:pt-24 lg:pb-24 pr-12 md:pr-16 lg:pr-6 fade-in-up'>
                <div className='eyebrow p5 line color--grey-4 mb-10 lg:mb-12'>
                  适用对象
                </div>
                <h2 className='h2 color--primary mb-12'>星选闪电服务于</h2>
              </div>
              <div className='col-span-4 md:col-start-1 lg:col-start-6 bg--grey-2 pr-12 md:pr-0 pb-20 md:pr-16 md:pb-24 lg:pt-24 lg:pb-24 lg:pr-24'>
                <div className='fade-in-up'>
                  <LinkWrap
                    to='/locations/'
                    className='btn--arrow--angle btn--arrow--angle--large color--primary hp_store'
                  >
                    <div className='color--grey-6'>找铺子</div>
                    <svg
                      width='13'
                      height='12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M12.415 9.72L12.39.24H2.887v1.824l6.288-.024-8.352 8.352 1.416 1.416 8.352-8.352V9.72h1.824z'></path>
                    </svg>
                  </LinkWrap>
                  <hr className='mt-4 mb-4' />
                  <LinkWrap
                    to='/merchants/'
                    className='btn--arrow--angle btn--arrow--angle--large color--primary hp_brand'
                  >
                    <div className='color--grey-6'>找品牌</div>
                    <svg
                      width='13'
                      height='12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M12.415 9.72L12.39.24H2.887v1.824l6.288-.024-8.352 8.352 1.416 1.416 8.352-8.352V9.72h1.824z'></path>
                    </svg>
                  </LinkWrap>
                  <hr className='mt-4 mb-4' />
                  <LinkWrap
                    to='/locations/'
                    className='btn--arrow--angle btn--arrow--angle--large color--primary hp_expand'
                  >
                    <div className='color--grey-6'>品牌扩张</div>
                    <svg
                      width='13'
                      height='12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M12.415 9.72L12.39.24H2.887v1.824l6.288-.024-8.352 8.352 1.416 1.416 8.352-8.352V9.72h1.824z'></path>
                    </svg>
                  </LinkWrap>
                  <hr className='mt-4' />
                </div>
              </div>
            </div>
          </div>
        </Inview>
      </div>
      <div className='bg--links'>
        <Inview triggerOnce={true}>
          <div className='image-bg zoom'>
            <Image filename='东鼎14.jpg' />
          </div>
        </Inview>
      </div>
    </section>
  )
}

export default Component
