import React from 'react'
import ButtonContact from 'components/ButtonContact'
import Inview from 'components/Inview'

const Component = () => {
  return (
    <section
      id='block-four-up'
      className='BlockFourUp BlockFourUp__Section-jwgow8-0 cSyOWs bg--primary'
    >
      <div className='container pt-20 pb-12 md:pt-24 md:pb-18 lg:pt-40 lg:pb-32'>
        <div className='grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
          <div className='col-span-4 md:col-span-2 mb-10 lg:mb-0'>
            <div className='top-160'>
              <Inview>
                <div className='eyebrow p5 line color--grey-1 fade-in-up'>
                  服务优势
                </div>
              </Inview>
            </div>
          </div>
          <div className='col-span-4 md:col-span-10'>
            <div className='grid grid-cols-4 md:grid-cols-10'>
              <div className='col-span-4 md:col-span-7 mb-12 md:mb-24'>
                <Inview>
                  <h2 className='h2 color--grey-1 fade-in-up'>
                    星选闪电
                    <span className='color--white-transparent'>的开店秘籍</span>
                  </h2>
                </Inview>
              </div>
            </div>
            <Inview>
              <div className='grid grid-cols-4 md:grid-cols-10 fade-in-up'>
                <div className='col-span-2 md:col-span-4 col-start-1 color--grey-1'>
                  <div className='md:flex'>
                    <div className='mr-6 mb-4'>
                      <svg
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='icon--bell'
                        viewBox='0 0 48 35'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M25.662 3.39V0h-3.5v3.281C11.62 3.864 3.25 12.597 3.25 23.286v1.75h40.071v-1.75c0-10.262-7.714-18.721-17.659-19.897zM39.73 21.535c-.874-8.31-7.903-14.786-16.444-14.786-8.542 0-15.57 6.476-16.445 14.786H39.73zm8.094 13.214H0v-3.5h47.824v3.5z'
                          fill='#FCFCF7'
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <h3 className='h4 md:mb-8 pr-4 md:pr-0'>最快3天开店</h3>
                      <p className='small hidden md:block'>
                        我们为您提供餐饮证照支持与设备进场，无需担心繁琐办证流程。
                      </p>
                      <p className='small hidden md:block'>
                        无论您是首次创业，还是拓张市场，有星选闪电助您快速拓店。
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-span-2 md:col-span-4 md:col-start-6 color--grey-1'>
                  <div className='md:flex'>
                    <div className='mr-6 mb-2'>
                      <svg
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='icon--arrow'
                        viewBox='0 0 55 55'
                      >
                        <path
                          d='M16.651 12.547l.047 3.591 18.89-.047L11.24 40.438l2.612 2.612L38.2 18.703v18.844h3.545v-25H16.65z'
                          fill='#FCFCF7'
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <h3 className='h4 md:mb-8 pr-4 md:pr-0'>
                        <span>更低成本，</span>
                        <span>更高利润</span>
                      </h3>
                      <p className='small hidden md:block'>
                        入驻我们的共享厨房更简单，更灵活，也更经济实惠。
                      </p>
                      <p className='small hidden md:block'>
                        您将省去前期一砖一瓦高昂的固定成本，节省堂食服务员的人力成本，节省调整规模或品类的沉没成本。让更大的利润空间掉进您自己的口袋。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Inview>
            <Inview>
              <div className='grid grid-cols-4 md:grid-cols-10 fade-in-up'>
                <div className='col-span-2 md:col-span-4 col-start-1 color--grey-1 mt-12 md:mt-20'>
                  <div className='md:flex'>
                    <div className='mr-6 mb-3'>
                      <svg
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='icon--people'
                        viewBox='0 0 45 35'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M32.86 0c-4.815 0-8.74 3.907-8.74 8.708 0 4.8 3.925 8.708 8.74 8.708a8.713 8.713 0 004.99-1.563 4.6 4.6 0 013.2 4.358V34h3.243V20.21a7.805 7.805 0 00-4.06-6.831 8.629 8.629 0 001.368-4.67c0-4.802-3.925-8.709-8.74-8.709zm-5.498 8.708c0-3.016 2.465-5.475 5.499-5.475 3.033 0 5.498 2.459 5.498 5.475 0 3.017-2.465 5.475-5.498 5.475-3.034 0-5.499-2.458-5.499-5.475zM11.432.626C6.615.626 2.69 4.533 2.69 9.334c0 1.703.494 3.293 1.346 4.636A7.806 7.806 0 000 20.836v13.79h3.242v-13.79a4.582 4.582 0 013.171-4.377 8.713 8.713 0 005.018 1.583 8.713 8.713 0 004.99-1.563 4.6 4.6 0 013.2 4.358v13.789h3.243v-13.79a7.805 7.805 0 00-4.06-6.831 8.629 8.629 0 001.368-4.671c0-4.8-3.925-8.708-8.74-8.708zm-5.5 8.708c0-3.016 2.466-5.475 5.5-5.475 3.033 0 5.498 2.459 5.498 5.475 0 3.016-2.465 5.475-5.499 5.475-3.033 0-5.498-2.459-5.498-5.475z'
                          fill='#FCFCF7'
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <h3 className='h4 md:mb-8 pr-4 md:pr-0'>面向更广客群</h3>
                      <p className='small hidden md:block'>
                        大数据智能选址帮您预先甄选优质地段及点位，匹配您的餐饮品类。
                      </p>
                      <p className='small hidden md:block'>
                        优质商圈的人流量，匹配周边潜在顾客，让您再也无需担心缺少餐饮需求。
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-span-2 md:col-span-4 md:col-start-6 color--grey-1 mt-12 md:mt-20'>
                  <div className='md:flex'>
                    <div className='mr-6 mb-4'>
                      <svg
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='icon--bars'
                        viewBox='0 0 48 29'
                      >
                        <path
                          d='M3 29V6M28 29V12M15.5 29V0'
                          stroke='#fff'
                          strokeWidth='4.7'
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <h3 className='h4 md:mb-8 pr-4 md:pr-0'>
                        <span>智慧厨房，</span>
                        <span>一键运营</span>
                      </h3>
                      <p className='small hidden md:block'>
                        AI智能厨房工具，远程管理，为您节省日常人员管理的中间环节，有效降低人力成本。
                      </p>
                      <p className='small hidden md:block'>
                        智能APP，一键了解店铺经营状况，双平台盈亏分析及账单数据看板。助您及时调整优化店铺运营策略。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Inview>
            <Inview>
              <div className='fade-in-up mt-12 md:mt-6 lg:mt-10'>
                <ButtonContact />
              </div>
            </Inview>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Component
