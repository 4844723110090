import React from 'react'
import Image from 'components/Image'
import ButtonContact from 'components/ButtonContact'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import Inview from 'components/Inview'

SwiperCore.use([Autoplay, Navigation])

class BlockSlideshow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
      hover: null,
    }
    this.setSelected = this.setSelected.bind(this)
    this.setHover = this.setHover.bind(this)
  }

  setSelected(id) {
    this.setState({
      selected: id,
    })
  }
  setHover(id) {
    this.setState({
      hover: id,
    })
  }

  render() {
    const groups = [
      // {
      //   title: '外卖',
      //   locations: [
      //     {
      //       id: 1,
      //       title: '北京 | 京师店',
      //       subgroups: [
      //         {
      //           id: 1,
      //           description:
      //             '针对外卖商户的储藏室，更合理利用场地空间，为您节省场地成本。',
      //           slides: ['202A9119x.jpg', 'WechatIMG374.jpeg'],
      //         },
      //         {
      //           id: 2,
      //           description:
      //             '整齐规范的场地空间规划，合理设计骑手取餐动线，助您提高出餐效率。',
      //           slides: ['202A9095x.jpg', '202A9100.jpg'],
      //         },
      //         {
      //           id: 3,
      //           description:
      //             '宽敞明亮的场地空间设计，助您安心的做好手中的每一道佳肴。',
      //           slides: ['202A9075.jpg', '202A9088.jpg'],
      //         },
      //         {
      //           id: 4,
      //           description: '宽敞取餐口设计，助您更顺手摆放，骑手更便捷取餐。',
      //           slides: ['202A9022x.jpg', '202A9034x.jpg'],
      //         },
      //         {
      //           id: 5,
      //           description: '完善的厨房基础设备，让您在装修改造方面无需操心。',
      //           slides: ['202A9006x.jpg', '202A9011.jpg'],
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        title: '外卖',
        locations: [
          {
            id: 1,
            title: '上海丨天潼路店',
            subgroups: [
              {
                id: 1,
                description:
                  '针对外卖商户的储藏室，更合理利用场地空间，为您节省场地成本。',
                slides: ['店铺-上海天潼路-1.jpg', '店铺-上海天潼路-2.jpg'],
              },
              {
                id: 2,
                description:
                  '整齐规范的场地空间规划，合理设计骑手取餐动线，助您提高出餐效率。',
                slides: ['店铺-上海天潼路-3.jpg', '店铺-上海天潼路-4.jpg'],
              },
              {
                id: 3,
                description:
                  '宽敞明亮的场地空间设计，助您安心的做好手中的每一道佳肴。',
                slides: ['店铺-上海天潼路-5.jpg', '店铺-上海天潼路-6.jpg'],
              },
              {
                id: 4,
                description: '宽敞取餐口设计，助您更顺手摆放，骑手更便捷取餐。',
                slides: ['店铺-上海天潼路-7.jpg', '店铺-上海天潼路-4.jpg'],
              },
            ],
          },
        ],
      },
      {
        title: '外卖+堂食',
        locations: [
          {
            id: 1,
            title: '上海 | 东鼎店',
            subgroups: [
              {
                id: 1,
                description:
                  '为您提供共享堂食区，统一提供堂食区服务，增加您的线下营业流水。',
                slides: ['东鼎18.jpg', '东鼎19.jpg'],
              },
              {
                id: 2,
                description:
                  '外卖的场地设计，合理的规划了清晰的外卖动线，帮助骑手快速找到您的店铺，外卖取餐不耽误。',
                slides: ['东鼎2.jpg', '东鼎4.jpg'],
              },
            ],
          },
          {
            id: 2,
            title: '上海 | 建涛店',
            subgroups: [
              {
                id: 1,
                description:
                  '为您提供共享堂食区，统一提供堂食区服务，增加您的线下营业流水。',
                slides: ['DSC_5831.jpg', 'DSC_5872.jpg'],
              },
              {
                id: 2,
                description:
                  '堂食+外卖的场地设计，合理的规划了清晰的外卖动线，帮助骑手快速找到您的店铺，外卖取餐不耽误。',
                slides: ['DSC_5850.jpg', 'DSC_5862.jpg'],
              },
            ],
          },
          {
            id: 3,
            title: '上海 | 金鹰店',
            subgroups: [
              {
                id: 1,
                description:
                  '为您提供优质的共享堂食区，统一提供堂食区服务，增加您的线下营业流水。',
                slides: [
                  '网络上传1280px-金鹰福市空间与装饰-9.jpg',
                  '网络上传1280px-金鹰福市空间与装饰-5.jpg',
                ],
              },
              {
                id: 2,
                description:
                  '堂食+外卖的场地设计，合理的规划了清晰的外卖动线，帮助骑手快速找到您的店铺，外卖取餐不耽误。',
                slides: [
                  '网络上传1280px-上海金鹰福市空间与装饰-4.jpg',
                  '网络上传1280px-上海金鹰福市空间与装饰-5.jpg',
                ],
              },
            ],
          },
        ],
      },
    ]

    return (
      <section className='block-slideshow-compare bg--grey-2'>
        <div className='container pt-20 pb-20 md:pb-24 md:pt-24 lg:pt-40 lg:pb-28'>
          {/* text + select */}
          <div className='grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
            <div className='col-span-2'>
              <div className='top-160'>
                <Inview>
                  <div className='eyebrow p5 line color--grey-4 mb-10 lg:mb-0 fade-in-up'>
                    业务概览
                  </div>
                </Inview>
              </div>
            </div>
            <div className='col-span-4 md:col-span-8 lg:col-span-10'>
              <Inview>
                <div className='grid grid-cols-4 md:grid-cols-8 lg:grid-cols-10 fade-in-up '>
                  <div className='col-span-4 md:col-span-8 lg:col-span-12'>
                    <h2 className='h2 color--primary mb-2 md:mb-0'>
                      看看我们的场地
                    </h2>
                  </div>
                  <div className='col-span-4 mt-8 lg:mt-16'>
                    <div
                      className={`primary btn--slider ${
                        this.state.selected === 0 ? 'delivery' : 'brick'
                      } hover-${this.state.hover}`}
                    >
                      <a
                        className='btn--slider-1'
                        onClick={() => this.setSelected(0)}
                        onMouseEnter={() => this.setHover(0)}
                        onMouseLeave={() => this.setHover(null)}
                      >
                        外卖
                        <span className='arrow arrow-bar is-right'></span>
                      </a>
                      <a
                        className='btn--slider-2'
                        onClick={() => this.setSelected(1)}
                        onMouseEnter={() => this.setHover(1)}
                        onMouseLeave={() => this.setHover(null)}
                      >
                        <span className='arrow arrow-bar is-left'></span>
                        外卖+堂食
                      </a>
                    </div>
                  </div>
                </div>
              </Inview>
            </div>
          </div>
          {/* sliders */}
          <Inview>
            <div
              className={`grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 mt-20 md:mt-20 lg:mt-28 fade-in-up`}
            >
              <div className='col-span-4 md:col-span-8 lg:col-start-3'>
                <div className='swiper-wrap'>
                  <SwiperComponent
                    selected={this.state.selected}
                    index={0}
                    group={groups[0]}
                  />
                  <SwiperComponent
                    selected={this.state.selected}
                    index={1}
                    group={groups[1]}
                  />
                </div>
              </div>
            </div>
          </Inview>
          {/* contact */}
          <Inview>
            <div
              className={`grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 mt-12 md:mt-16 lg:mt-20`}
            >
              <div className='col-span-4 md:col-span-8 lg:col-span-12 lg:col-start-3 fade-in-up'>
                <ButtonContact />
              </div>
            </div>
          </Inview>
        </div>
      </section>
    )
  }
}

export default BlockSlideshow

class SwiperComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeLocationId: null,
      activeSubgroupId: null,
    }
    this.updateState = this.updateState.bind(this)
    this.handleSwiperUpdate = this.handleSwiperUpdate.bind(this)
  }

  updateState = (slide) => {
    this.setState({
      activeLocationId: Number(slide.dataset.location),
      activeSubgroupId: Number(slide.dataset.subgroup),
    })
  }

  handleSwiperUpdate = (swiper) => {
    let getActiveIndex = swiper.activeIndex
    if (window.innerWidth >= 600) {
      getActiveIndex--
    }
    const getActiveSlide = swiper.slides[getActiveIndex]
    this.updateState(getActiveSlide)
  }

  render() {
    const { selected, index, group } = this.props

    let slideCount = 0

    group.locations.forEach((location) =>
      location.subgroups.forEach((subgroup) =>
        subgroup.slides.forEach((slide) => {
          slideCount++
        })
      )
    )

    return (
      <div className={`swiper-component ${selected === index ? 'active' : ''}`}>
        <div className='swiper-nav mb-4'>
          <div className={`swiper-nav-item swiper-prev swiper-prev-${index}`}>
            <div className={`arrow arrow-bar is-left`} />
          </div>
          <div className={`swiper-nav-item swiper-next swiper-next-${index}`}>
            <div className={`arrow arrow-bar is-right`} />
          </div>
        </div>
        <Swiper
          speed={600}
          navigation={{
            nextEl: `.swiper-next-${index}`,
            prevEl: `.swiper-prev-${index}`,
          }}
          // autoplay={{
          //   delay: 4000,
          // }}
          loop={true}
          loopedSlides={slideCount}
          loopAdditionalSlides={1}
          slideToClickedSlide={true}
          onSwiper={(swiper) => this.handleSwiperUpdate(swiper)}
          onSlideChange={(swiper) => this.handleSwiperUpdate(swiper)}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
        >
          {group.locations.map((location, i) =>
            location.subgroups.map((subgroup, j) =>
              subgroup.slides.map((slide, k) => (
                <SwiperSlide key={k} data-location={i} data-subgroup={j}>
                  <Slide slide={slide} />
                </SwiperSlide>
              ))
            )
          )}
        </Swiper>

        <div className='content-box mt-16'>
          <div className='locations'>
            {group.locations.map((location, i) => {
              return (
                <h4
                  className={`h4 ${
                    this.state.activeLocationId === i ? 'active' : ''
                  }`}
                  key={i}
                >
                  {location.title}
                </h4>
              )
            })}
            <h4 className='placeholder'>{group.locations[0].title}</h4>
          </div>

          <div className='subgroups mt-4'>
            {group.locations.map((location, i) =>
              location.subgroups.map((subgroup, j) => {
                return (
                  <p
                    className={`small ${
                      this.state.activeLocationId === i &&
                      this.state.activeSubgroupId === j
                        ? 'active'
                        : ''
                    }`}
                    key={j}
                  >
                    {subgroup.description}
                  </p>
                )
              })
            )}
            <p className='placeholder'>
              {group.locations[0].subgroups[0].description}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

const Slide = ({ slide }) => {
  return (
    <div className='col-span-4 md:col-span-2 lg:col-span-4 image-wrap'>
      <Image filename={slide} />
    </div>
  )
}
